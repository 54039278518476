import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { errorMapper } from "api/methods/errorMapper";
import { sagaActions } from "../sagaActions";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";
import { LogoutSaga } from "common_modules/auth/sagas/methods/logoutSaga";
import { getFirstErrorFromResponse } from "../../../api/methods/getFirstErrorFromResponse";

const getTextFromAxiosErrorMessage = (message: string) => {
  if (message?.includes("Network")) {
    return "Ошибка сети. Проверьте подключение к интернету.";
  }
  if (message?.includes("timeout")) {
    return "Превышено время ожидания. Повторите запрос позднее.";
  }
  return message;
};

export type errorHandlerSagaType = {
  response: any;
  processType?: string;
  handledStatuses?: Array<number>;
};

export function* errorHandlerSaga({
  response: Error,
  processType,
  handledStatuses,
}: errorHandlerSagaType) {
  try {
    const response = Error?.response;

    console.log(Error.request);
    console.log(Error.message);

    if (processType) {
      yield put(setProcessLoading(processType, false));
    }
    // No error object case
    if (!Error) {
      //  TODO API
      // yield put(
      //   uiReducer.actions.setModal({
      //     status: "error",
      //     message: "Неизвестная ошибка",
      //   })
      // );
      axios
        .post(process.env.REACT_APP_NOTIF_URL as string, {
          message_type: "HTTP_ERROR_500",
          application_mode: "dev",
          message: {
            body: `Front. No error object`,
          },
        })
        .catch(() => {});
      return;
    }

    // No response case
    if (!response) {
      // TODO API
      // yield put(
      //   uiReducer.actions.setModal({
      //     status: "error",
      //     message:
      //       getTextFromAxiosErrorMessage(Error?.message) ||
      //       "Неизвестная ошибка",
      //   })
      // );
      axios
        .post(process.env.REACT_APP_NOTIF_URL as string, {
          message_type: "HTTP_ERROR_500",
          application_mode: "dev",
          message: {
            body: `Front. Ошибка кода: ${Error.message || ""}`,
          },
        })
        .catch(() => {});
      return;
    }

    // Mapping logic
    /* trying map errors to fields */
    const firstError = getFirstErrorFromResponse(response);
    if (firstError) {
      yield put(errorsReducer.actions.setGlobalError(firstError));

      if (response.status == 400 || response.status == 401) {
        yield put(errorsReducer.actions.setFieldsErrors(errorMapper(response)));
      }
      if (response.status == 401) {
        //TODO uncomment
        // yield call(LogoutSaga);
        return;
      }
    }

    /* handle 5XX errors */
    if (response.status >= 500) {
      // TODO API
      // yield put(
      //   uiReducer.actions.setModal({
      //     status: "error",
      //     message: "Временная 500 ошибка сервера",
      //   })
      // );
      return;
    }

    /* if status was not handled by saga, set global modal */
    if (!handledStatuses?.includes(response.status)) {
      // TODO API
      // yield put(
      //   uiReducer.actions.setModal({
      //     status: "error",
      //     message: firstError || "Ошибка.",
      //   })
      // );
    }
  } catch (e: any) {
    /* handle error handling */
    // TODO API
    // yield put(
    //   uiReducer.actions.setModal({
    //     status: "error",
    //     message: "Unhandled error.",
    //   })
    // );
  }
}

function* errorHandlerReduxWrapper({
  payload,
  type,
}: PayloadAction<errorHandlerSagaType>) {
  yield errorHandlerSaga(payload);
}

export function* errorWatcherSaga() {
    yield takeEvery(sagaActions.error, errorHandlerReduxWrapper);
}
