import { mainAxios } from "../index";
import { dataToQueryString } from "../methods/dataToQueryString";
import {
   getAllApplicationsQueryType,
   getAllLoansQueryType,
   getAllPaymentsQueryType,
   singleApplicationType,
   singleFinancialType,
   singleLoanType,
} from "./types";
import { AxiosResponse } from "axios";
import { getAdminDocumentType, getPhotoType } from "../underwriter/types";
import { underwriterAxios } from "../underwriter";

type getAllLoansResponseType = {
   data: {
      items: singleLoanType[];
      items_count: number;
   };
};

export type getAllApplicationResponseType = {
   data: {
      items: singleApplicationType[];
      items_count: number;
   };
};

class adminApiClass {
   constructor() {}

   getAllLoans = (
      query: getAllLoansQueryType
   ): Promise<AxiosResponse<getAllLoansResponseType>> =>
      mainAxios.get(`/loan${dataToQueryString(query)}`);

   getAllApplications = (
      query: getAllPaymentsQueryType
   ): Promise<AxiosResponse<getAllApplicationResponseType>> =>
      mainAxios.get(`/application${dataToQueryString(query)}`);

   getAllPayments = (
      query: getAllApplicationsQueryType
   ): Promise<AxiosResponse<getAllApplicationResponseType>> =>
      mainAxios.get(`/payment${dataToQueryString(query)}`);

   getAllPayouts = (
      query: getAllApplicationsQueryType
   ): Promise<AxiosResponse<getAllApplicationResponseType>> =>
      mainAxios.get(`/payout${dataToQueryString(query)}`);

   // "id": 1718,
   getApplicationById = (
      applicationId: string
   ): Promise<AxiosResponse<{ data: singleApplicationType }>> =>
      mainAxios.get(`/application/${applicationId}`);

   // "id": "1ed77a8e-4566-6e1a-a374-13e68f3ac322",
   getLoanById = (
      loanId: string
   ): Promise<AxiosResponse<{ data: singleLoanType }>> =>
      mainAxios.get(`/loan/${loanId}`);

   getPaymentById = (
      paymentId: string
   ): Promise<AxiosResponse<{ data: singleFinancialType }>> =>
      mainAxios.get(`/payment/${paymentId}`);

   getPayoutById = (
      payoutId: string
   ): Promise<AxiosResponse<{ data: singleApplicationType }>> =>
      mainAxios.get(`/payout/${payoutId}`);

   downloadFile = ({
      applicationId,
      userId,
      document_type,
   }: getAdminDocumentType) =>
      mainAxios.get(
         `/user/${userId}/application/${applicationId}/${document_type}/file:download`
      );

   downloadFileByUserId = ({ userId, applicationId }: getAdminDocumentType) =>
      mainAxios.get(`/user/${userId}/application/${applicationId}`);

   getPhotoPreview = ({ applicationId, photo_type, userId }: getPhotoType) =>
      mainAxios.get(
         `/user/${userId}/application/${applicationId}/document-photo/${photo_type}/photo:download-preview`
      );

   getPhotoPreviewCar = ({ applicationId, photo_type, userId }: getPhotoType) =>
      mainAxios.get(
         `/user/${userId}/application/${applicationId}/car-photo/${photo_type}/photo:download-preview`
      );
}

export const adminApi = new adminApiClass();
