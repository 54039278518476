import React, { AllHTMLAttributes, FC } from "react";
import styles from "./index.module.css";
import cn from "classnames";

type propsType = {
  variant?: "default" | "white";
} & AllHTMLAttributes<HTMLDivElement>;

export const Title: FC<propsType> = ({
  variant,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={cn(styles.container, {
        [`${className}`]: className,
        [styles[`variant_${variant}`]]: variant !== "default",
      })}
    >
      {children}
    </div>
  );
};
