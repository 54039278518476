import get from "lodash/get";
import { TextInputPropsType } from "../types";
import { InputBase } from "../InputBase";
import { ConnectedForm } from "../../ConnectedForm";
import React from "react";

export const InputText = (props: Omit<TextInputPropsType, "control">) => {
   return (
      <ConnectedForm>
         {({ control, formState: { errors } }) => (
            <InputBase
               {...props}
               error={
                  props.error || get(errors as any, `${props.name}.message`)
               }
               control={control}
            />
         )}
      </ConnectedForm>
   );
};
