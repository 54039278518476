import { authApi } from "./auth";
import { mainApi } from "./main";
import { underwriterApi } from "./underwriter";
import * as axios from "axios";
import { adminApi } from "./admin";

export const mainAxios = axios.default.create({
   baseURL: process.env.REACT_APP_MAIN_URL,
   timeout: 30000,
});

export const API = {
   admin: adminApi,
   auth: authApi,
   main: mainApi,
   underwriter: underwriterApi,
};
