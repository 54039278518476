export type localStorageType = {
    user_id: string | null;
    merchant_id: string | null;
    token: string | null;
    phone: string | null;
    transhForm: {
        [key: string]: any;
    } | null;
    applicationMerchant: {
        [key: string]: any;
    } | null;
    didTranshTryRestore: boolean;
};
export type localStorageKeysType = keyof localStorageType;
export const arrayOfObjectsInLocalStorage: Array<localStorageKeysType> = [
    "transhForm",
    "applicationMerchant",
    "didTranshTryRestore",
];
