import React, { FC } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import { Header } from "./components/Header";
import { Phone } from "./pages/Phone";
import { Title } from "./components/Title";
import { Subtitle } from "./components/Subtitle";
import { RestorePassword } from "./pages/RestorePassword";
import { Password } from "./pages/Password";
import { useSelector } from "react-redux";
import { StateType } from "store";
import { stringHelpers } from "helpers/string";
import { Icons } from "assets/icons";
import { useMedia } from "hooks/logical/useMedia";
import { authRoutes } from "../../../common_modules/auth/authRoutes";
import manInCar from "assets/image/manInCar.jpg";
import { Code } from "./pages/Code";
import cn from "classnames";

export const Auth: FC = () => {
   const { pathname } = useLocation();
   const history = useHistory();

   const phoneNumber = useSelector(
      (state: StateType) => state.userReducer.phone
   );

   const codeSubtitle = (
      <>
         Введите код подтверждения,
         <br />
         отправленный на номер: <br />
         <div className={styles.phone_text}>
            {stringHelpers.phoneNormalize(phoneNumber as string)}
            <Icons.ui.PencilBlackNew
               className={styles.pencil_icon}
               onClick={() => history.push(authRoutes.phone)}
            />
         </div>
      </>
   );

   const authPageSubtitles = {
      [`${authRoutes.phone}`]: (
         <>
            Введите свой номер мобильного телефона
            <br /> для регистрации или входа в систему.
         </>
      ),
      [`${authRoutes.password}`]: (
         <>
            Введите пароль, который вы устанавливали <br /> для входа в личный
            кабинет.
         </>
      ),
      [`${authRoutes.code}/restore`]: codeSubtitle,
      // [`${routes.Auth.code}/register`]: codeSubtitle,
      // [`${routes.Auth.restore_password}`]: "Придумайте и введите пароль",
   };

   const { width } = useMedia();
   console.log("pathname", pathname);
   return (
      <div className={styles.container}>
         {width < 1023 && <Header />}

         <div
            className={cn(styles.content_container, {
               [styles.container_patch]:
                  pathname === `${authRoutes.code}/restore`,
            })}
         >
            <Title className={styles.title}>
               {" "}
               {pathname === "/user/code/restore" ||
               pathname === "/user/restore_password"
                  ? "Восстановление пароля"
                  : "Войти в Creddy"}
            </Title>
            <Subtitle className={styles.subtitle}>
               {authPageSubtitles[pathname]}
            </Subtitle>

            <Route path={authRoutes.phone}>
               <Phone />
            </Route>

            <Route path={authRoutes.password}>
               <Password />
            </Route>

            <Route path={authRoutes.code + "/:codeMode"}>
               <Code />
            </Route>

            <Route path={authRoutes.restore_password}>
               <RestorePassword />
            </Route>
         </div>

         <div className={styles.right_image_part}>
            <img src={manInCar} alt={"man in car"} />
            {/*<div className={styles.image_title}>*/}
            {/*  <CreddyLogo className={styles.creddy_logo} />*/}
            {/*  Современная <br /> и технологичная <br /> компания*/}
            {/*</div>*/}
         </div>

         {/* {![routes.Auth.phone].includes(pathname)
    &&
    <div className={styles.desktop_gradient}>
      <Triangle className={styles.triangle_1} />
      <Triangle className={styles.triangle_2} />
    </div>} */}

         {/* Тут экран со свинюшкой */}
         {/* {false
    &&
    <div className={styles.desktop_gradient}>
      <Triangle className={styles.triangle_1} />
      <Triangle className={styles.triangle_2} />
    </div>} */}
      </div>
   );
};
