import React, { FC } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { Button } from "../../components/Button";
import { SmartForm } from "components/hocs/SmartForm";
import { userReducerSelectors } from "common_modules/auth/selectors";
import { Icons } from "assets/icons";
import { useHistory } from "react-router-dom";
import { errorsSelectors } from "store/auxiliary/errorsReducer";
import { processLoading } from "store/auxiliary/loadingReducer";
import { clearState } from "../../../../../store/auxiliary/clearState";
import { authRoutes } from "../../../../../common_modules/auth/authRoutes";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import { setTokenToAxios } from "../../../../../common_modules/auth/api/methods/setTokenToAxios";
import { InputText } from "../../../../ui/Inputs/InputText";
import { InputPassword } from "../../../../ui/Inputs/InputPassword";

type formType = {
  password: string;
  phone_number: string;
};

export const Password: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const phoneNumber = useSelector(userReducerSelectors.getPhone);

  const error = useSelector(errorsSelectors.globalError);

  const loading = useSelector(
    processLoading.get(authSagaActions.getToken.type)
  );

  const form = useForm<formType>({
    defaultValues: {
      phone_number: phoneNumber,
    },
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const submit = (data: formType) => {
    dispatch(
      authSagaActions.getToken({
        password: data.password,
        requiredUserRole: "admin",
      })
    );
  };

  const forgotPassword = () => {
    dispatch(
      authSagaActions.getCode({
        smsTemplate: "restore",
      })
    );
  };

  const handleLogout = () => {
    dispatch(clearState());
    history.push(authRoutes.phone);
    lsController.clearAll();
    // костыль из-за typeof null object
    lsController.set("token", "")
    setTokenToAxios(null);
  };


  return (
    <div className={styles.container}>
      <SmartForm form={form} submit={submit}>
        <InputText
           className={styles.input}
          name={"phone_number"}
          label={"Номер телефона"}
          variant={"large"}
          mask={"+7 999 999 99 99"}
          support={
            <Icons.ui.PencilBlack
              className={styles.support}
              data-class={"dim"}
              onClick={() => handleLogout()}
            />
          }
          disabled
        />

        <InputPassword
           className={styles.input}
          name={"password"}
          label="Пароль"
          autoFocus
          error={form.formState?.errors?.password?.message}
        />

        <Button loading={loading} className={styles.button}>
          Продолжить
        </Button>
      </SmartForm>

      <Button
        variant={"underline"}
        className={styles.forgot_password}
        onClick={forgotPassword}
      >
        Не помню пароль
      </Button>
    </div>
  );
};
