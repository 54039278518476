import { takeEvery } from "redux-saga/effects";
import { authSagaActions } from "./actions";
import { getUserSaga } from "./methods/getUser";
// import { createUserSaga } from "./methods/createUser";
// import { getCodeSaga } from "./methods/getCode";
import { verifyCodeSaga } from "./methods/verifyCode";
// import { createPasswordSaga } from "./methods/createPassword";
import { getTokenSaga } from "./methods/getToken";
import {getCodeSaga} from "./methods/getCode";
import { createPasswordSaga } from "./methods/createPassword";

/*
  Аус из модулей работает весь, кроме логики создания юзера
  все методы и верстка перенесены, но закоменчены.
  По идее достаточно будет переписать импорты в файлах, чтобы они смотрели внутрь common_modules
  Пока бросил создание юзера за ненадобностью
  (в андере и админке его нет, а в мейне пока останется старая рега)
 */

export function* authWatcher() {
  yield takeEvery(authSagaActions.getUser, getUserSaga);
  // yield takeEvery(authSagaActions.createUser, createUserSaga);
  yield takeEvery(authSagaActions.getCode, getCodeSaga);
  yield takeEvery(authSagaActions.verifyCode, verifyCodeSaga);
  yield takeEvery(authSagaActions.createPassword, createPasswordSaga);
  yield takeEvery(authSagaActions.getToken, getTokenSaga);
}
