import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import "./assets/styles/index.css";
import { routes } from "./routes";
import { Router } from "./routes/RouterDevTools";
import { createArrayRouter } from "./routes/createRouter";
import { lazyLoad } from "./helpers/lazyLoad";
import { authRoutes } from "./common_modules/auth/authRoutes";
import { useSelector } from "react-redux";
import { processLoading } from "./store/auxiliary/loadingReducer";
import { authSagaActions } from "./common_modules/auth/sagas/actions";
import { Auth } from "./components/pages/Auth";
import { LoaderPage } from "./components/ui/LoaderPage";

const Sandbox = lazyLoad(() => import("./components/pages/Sandbox"));
const Main = lazyLoad(() => import("./components/pages/Main"));

function App() {
   const autoAuthProcess = useSelector(
      processLoading.get(authSagaActions.autoAuth.type)
   );
   useEffect(() => {
      // history.push(routes.main.applications);
   }, []);

   console.log("auth URL", process.env.REACT_APP_AUTH_URL);
   console.log("api URL", process.env.REACT_APP_MAIN_URL);
   console.log("autoAuthProcess", autoAuthProcess);

   if (autoAuthProcess) {
      return <LoaderPage />;
   }

   return (
      <>
         <Route path={authRoutes.root}>
            <Auth />
         </Route>

         <Route path={routes.main.root}>
            <Main />
         </Route>
         {process.env.NODE_ENV == "development" && (
            <>
               {/* это дев хелпер для роутов*/}
               <Router router={createArrayRouter(routes)} />

               <Route path={routes.sandbox}>
                  <Sandbox />
               </Route>
            </>
         )}
      </>
   );
}

export default App;
