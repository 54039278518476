import { authApi } from "../index";
import { lsController } from "store/auxiliary/localStorage";

export const setUserIdToApiClass = (user_id: string | null) => {
  authApi.user_id = user_id;
  // applicationApi.user_id = user_id;
  // userSaleApi.user_id = user_id;

  lsController.set("user_id", user_id);
};
