import { takeEvery } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { approveApplicationSaga } from "./approveApplicationSaga";
import { declineApplicationSaga } from "./declineApplicationSaga";
import { updateApplicationSaga } from "./updateApplicationSaga";
import { downloadFileSaga } from "./downloadFileSaga";
import { pingApplicationsSaga } from "./pingApplicationsSaga";
import { getApplicationsSaga } from "./getApplicationsSaga";

export function* underwriterSaga() {
  yield takeEvery(
    sagaActions.underwriter.approveApplication,
    approveApplicationSaga
  );
  yield takeEvery(
    sagaActions.underwriter.declineApplication,
    declineApplicationSaga
  );
  yield takeEvery(
    sagaActions.underwriter.updateApplication,
    updateApplicationSaga
  );
  yield takeEvery(sagaActions.underwriter.downloadFile, downloadFileSaga);

  yield takeEvery(
    sagaActions.underwriter.pingApplications,
    pingApplicationsSaga
  );

  yield takeEvery(sagaActions.underwriter.getApplications, getApplicationsSaga);
}
