import { authAxios, getPhoneWithoutToken } from "../index";
// import { mainAxios } from "../main";
// import { underwriterAxios } from "../underwriter";
import { lsController } from "store/auxiliary/localStorage";
import { underwriterAxios } from "../../../../api/underwriter";
import { mainAxios } from "../../../../api";
import * as axios from "axios";

export const setTokenToAxios = (token?: string | null) => {
   const transformedToken = token ? "Bearer " + token : "";
   getPhoneWithoutToken.defaults.headers.common["Authorization"] = "";
   authAxios.defaults.headers.common["Authorization"] = transformedToken;
   mainAxios.defaults.headers.common["Authorization"] = transformedToken;
   underwriterAxios.defaults.headers.common["Authorization"] = transformedToken;

   lsController.set("token", token || "");
};
