/**
 * @payload
 * -- password
 * -- password_confirm
 * -- code
 */
import { PayloadAction } from "@reduxjs/toolkit";
import { call } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { getDocumentType } from "../../api/underwriter/types";
import { downloadFile } from "../../helpers/file";
import { processStateController } from "../util/helpers/processStateController";

export function* downloadFileSaga({
  payload,
  type: actionType,
}: PayloadAction<getDocumentType>) {
  const process = processStateController(actionType);
  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.underwriter.downloadFile,
      payload
    );

    //TODO достать id из стора
    const url = response?.data?.data?.content;
    downloadFile(url, `report_check_vehicle_43`, "_blank");
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.downloadFile.type,
    });
  } finally {
    yield process.stop();
  }
}
