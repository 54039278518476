/**
 * @payload
 * -- password
 * -- password_confirm
 * -- code
 */
import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { getAdminDocumentType } from "../../api/underwriter/types";
import { downloadFile } from "../../helpers/file";
import { processStateController } from "../util/helpers/processStateController";
import { documentToDownloadType } from "../../components/pages/Main/components/pages/ApplicationPage/SignedDocumentsSection/DocumentDownload";

const documentTypeDescriptionMap: { [key in documentToDownloadType]?: string } =
   {
      check_fio_report: "Проверка клиента",
      check_vehicle_report: "Отчет по автомобилю",
      bki_history: "Согласие на безакцептное списание",
      deposit_contract: "Договор залога",
      loan_contract: "Договор ИУ",
      personal_data_agreement: "Согласие с обработкой персональных данных",
      applying_agreement: "Анкета-заявление",
      processing_of_personal_data: "Согласие с обработкой перс.данных",
      risk_notification: "Уведомление о существующих рисках",
   };

export function* downloadAdminFileSaga({
   payload,
   type: actionType,
}: PayloadAction<getAdminDocumentType>) {
   const process = processStateController(actionType);
   try {
      yield process.start();

      const response: sagaApiType = yield call(API.admin.downloadFile, payload);

      const url = response?.data?.data?.content;

      let nameFileDownload =
         documentTypeDescriptionMap[
            payload.document_type as documentToDownloadType
         ] || payload.document_type;

      downloadFile(url, `${nameFileDownload}`, "_blank");
   } catch (e: any) {
      yield call(errorHandlerSaga, {
         response: e,
         processType: sagaActions.admin.downloadFile.type,
      });
   } finally {
      yield process.stop();
   }
}
