import * as axios from "axios";
import {
   createPasswordType,
   createUserType,
   getCodeType,
   getTokenType,
   verifyCodeType,
} from "../sagas/types";
import { stringHelpers } from "helpers/string";

export const authAxios = axios.default.create({
   baseURL: process.env.REACT_APP_AUTH_URL,
   timeout: 30000,
});

console.log(authAxios.defaults.headers.common);

export const getPhoneWithoutToken = axios.default.create({
   baseURL: process.env.REACT_APP_AUTH_URL,
   timeout: 30000,
});

class AuthApiClass {
   user_id: string | null = null;

   constructor() {}

   get = ({ phone }: { phone: string }) =>
      getPhoneWithoutToken.get(
         `/user?phone_number=${stringHelpers.clearPhone(phone)}`
      );

   create = ({ phone, ...body }: createUserType) =>
      authAxios.post("/user", {
         phone_number: stringHelpers.clearPhone(phone),
         ...body,
      });

   code = (props: getCodeType) =>
      authAxios.post(`/user/${this.user_id}/code`, {
         template: props.smsTemplate,
         target: "phone",
      });

   verifyCode = (props: verifyCodeType) =>
      authAxios.head(`/user/${this.user_id}/code/${props.code}`);

   createPassword = ({ code, ...body }: createPasswordType) =>
      authAxios.put(`/user/${this.user_id}/code/${code}`, body);

   token = (body: getTokenType) =>
      authAxios.post(`/user/${this.user_id}/token`, body);

   getUserTokenized = () => authAxios.get(`/user/${this.user_id}`);

   getPdDocument = ({ id }: { id: string }) => authAxios.get(`/pd/${id}`);
}

export const authApi = new AuthApiClass();
