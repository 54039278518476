import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentApiTypes } from "../../../types/documents/types";

interface DocumentsState extends Record<DocumentApiTypes.documentType, any> {
   applicationId: number;
}

const initialState: DocumentsState = {
   applicationId: 0,
   passport_main: null,
   optional_passport_main: null,
   passport_registration: null,
   optional_passport_registration: null,
   driver_license_front: null,
   driver_license_back: null,
   selfie_with_passport: null,
   vehicle_registration_certificate_front: null,
   vehicle_registration_certificate_back: null,
   pts_front: null,
   pts_back: null,

   front_right: null,
   front_left: null,
   back_right: null,
   back_left: null,
   dashboard: null,
   vin: null,
   selfie_with_car: null,
   interior: null,

   archive_photo: null,
};

export const documentsReducer = createSlice({
   name: "@documentsReducer",
   initialState,
   reducers: {
      setPhotoInfo: (
         state,
         {
            payload,
         }: PayloadAction<{
            photo_type: DocumentApiTypes.documentType;
            data: any;
         }>
      ) => {
         state[payload.photo_type] = payload.data;
      },
      setApplicationId: (state, action: PayloadAction<number>) => {
         state.applicationId = action.payload;
      },
      clear: (state) => {
         return {
            ...initialState,
            applicationId: state.applicationId,
         };
      },
   },
});
