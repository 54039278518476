import {
  attachingCarPhotoToTheApplicationType,
  attachingDocumentToTheApplicationType,
  downloadPdf,
  downloadPdfWithIdType,
  getCarPhotoFull,
  getCarPhotoPreview,
  getDocumentFull,
  getDocumentPreview,
  getInfoFromDocumentType,
  uploadDocumentType,
} from "./types";
import { AxiosResponse } from "axios";
import { createRequest } from "../../methods/createRequest";
import { mainAxios } from "../../index";

class DocumentsClass {
  // number or string?
  user_id: number | null = null;

  constructor() {}

  getDocumentPreview = ({ applicationId, photo_type }: getDocumentPreview) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}/photo:download-preview`
    );

  getDocumentFull = ({ applicationId, photo_type }: getDocumentFull) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}/photo:download-full`
    );

  getCarPhotoPreview = ({ applicationId, photo_type }: getCarPhotoPreview) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}/photo:download-preview`
    );

  getCarPhotoFull = ({ applicationId, photo_type }: getCarPhotoFull) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}/photo:download-full`
    );

  getInfoFromDocument = ({
    applicationId,
    photo_type,
  }: getInfoFromDocumentType): Promise<AxiosResponse<any>> =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`
    );

  downloadPdfWithId = ({ applicationId, hash }: downloadPdfWithIdType) =>
    mainAxios.get(
      `/user/${this.user_id}/application/${applicationId}/applying-agreement/${hash}/photo:download`
    );

  downloadPdf = ({ hash }: downloadPdf) => mainAxios.get(`/agr/${hash}`);

  downloadLoanContract = ({ hash }: downloadPdf) =>
    mainAxios.get(`/loan-contract/${hash}`);

  // нужен для сохранения документа на серве, возвращает id, в заявки закидываем именно id
  uploadDocument = (body: uploadDocumentType): Promise<AxiosResponse<any>> =>
    mainAxios.post(`/user/${this.user_id}/document`, body);

  attachingDocumentToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingDocumentToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/document-photo/${photo_type}`,
      body
    );

  attachingCarPhotoToTheApplication = ({
    applicationId,
    photo_type,
    ...body
  }: attachingCarPhotoToTheApplicationType) =>
    mainAxios.post(
      `/user/${this.user_id}/application/${applicationId}/car-photo/${photo_type}`,
      body
    );
}

export const documentsApi = new DocumentsClass();
