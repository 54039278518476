import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { rootSaga } from "../sagas";
import { loadingReducer } from "./auxiliary/loadingReducer";
import { errorsReducer } from "./auxiliary/errorsReducer";
import { documentsReducer } from "./reducers/documents";
import { applicationsReducer } from "./reducers/applications";
import { uiReducer } from "./reducers/ui";
import { userReducer } from "common_modules/auth/userReducer";

export type AppDispatch = typeof store.dispatch;

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export const stateActions = {
  authActions: userReducer.actions,
  loadingReducerActions: loadingReducer.actions,
  documentsActions: documentsReducer.actions,
  applicationsActions: applicationsReducer.actions,
};

const reducer = combineReducers({
  router: connectRouter(history),
  userReducer: userReducer.reducer,
  documentsReducer: documentsReducer.reducer,
  applicationsReducer: applicationsReducer.reducer,
  loadingReducer: loadingReducer.reducer,
  errorsReducer: errorsReducer.reducer,
  uiReducer: uiReducer.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
          .concat(sagaMiddleware)
          .concat(routerMiddleware(history)),
});
sagaMiddleware.run(rootSaga);

export type StateType = ReturnType<typeof reducer>;
