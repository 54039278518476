import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";

var timeoutId = 123;

type propsType = {
  length?: number | string;
  className?: string;
  placeholder?: string;
  error?: string;
  timeout?: number;
  onChange(value: string): void;
  timerCallback?(): void;
  sendCodeHandler?(): void;

  tempInput: any;
  fieldsArray: Array<string>;
  arrayOfInputs: any;
  setFieldsArray: (val: any) => void;
  onClickHandler: any;
  pasteHandler: any;
  keyPressHandler: any;
  onChangeHandler: any;
  retryCount: number;
  setRetryCount: React.Dispatch<React.SetStateAction<number>>;
};

export const CodeField: React.FC<propsType> = (props) => {
  const {
    error,
    onChange,
    className,
    length = 4,
    placeholder,
    sendCodeHandler,
    timeout = 60,
    fieldsArray,
    setFieldsArray,
    tempInput,
    arrayOfInputs,
    keyPressHandler,
    onClickHandler,
    pasteHandler,
    timerCallback,
    onChangeHandler,
    retryCount,
    setRetryCount,
  } = props;
  let codeLength = Number(length);

  /* state */
  const [timer, setTimer] = useState(timeout);

  // это будет в useCodeFieldLogic
  // const [sendCodeCounter, setSendCodeCounter] = useState(1);

  /* methods */
  function createTimer() {
    window.clearInterval(timeoutId);
    timeoutId = window.setInterval(updateTimer, 1000);
  }

  function updateTimer() {
    setTimer((timer) => {
      if (timer <= 0) {
        window.clearInterval(timeoutId);
        return 0;
      } else {
        return timer - 1;
      }
    });
  }

  function printTimer() {
    return (
      (timer / 60 < 60 ? "0" : "") +
      Math.floor(timer / 60) +
      ":" +
      (timer % 60 < 10 ? "0" : "") +
      (timer % 60)
    );
  }

  /* effects */
  useEffect(() => {
    timeoutId = window.setInterval(updateTimer, 1000);
  }, []);

  return (
    <div
      className={cn({
        [`${className}`]: className,
        [styles.wrapper]: true,
        [styles.wrapper_error]: error,
      })}
    >
      <div className={styles.CodeField}>
        {fieldsArray.map((_, index) => {
          const disabled = tempInput != index && !fieldsArray[index];

          const isFilled = fieldsArray[index];

          function handlerRef(el: any) {
            arrayOfInputs.current[index] = el;
          }

          function handlerClick(event: React.MouseEvent<HTMLInputElement>) {
            onClickHandler(event, index);
          }

          return (
            <div
              className={cn(styles.input_wrapper, {
                [styles.input_wrapper__error]: error,
                [styles.input_wrapper__filled]: isFilled,
              })}
            >
              <input
                className={cn({
                  [styles.wrapper_error_number]: error,
                })}
                type="tel"
                key={index}
                maxLength={1}
                id={`${index}-id`}
                data-cy={`field-${index}`}
                placeholder={placeholder}
                value={fieldsArray[index]}
                // disabled={disabled}
                autoFocus={index == 0}
                ref={handlerRef}
                onChange={onChangeHandler}
                onKeyDown={keyPressHandler}
                onClick={handlerClick}
                onPaste={pasteHandler}
              />
            </div>
          );
        })}
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.timer}>
        {timer >= -1 && (
          <>
            <div
              onClick={() => {
                if (timer == 0 && retryCount < 5) {
                  setTimer(timeout);
                  setRetryCount(retryCount + 1);
                  createTimer();
                }
              }}
              className={cn(styles.timer_text, {
                [styles["resend_code--disabled"]]: retryCount == 5,
              })}
            >
              <div className={styles.repeat_title}>
                {" "}
                {timer > 0 ? (
                  <>
                    Запросить код еще раз <br /> через{" "}
                    <span>
                      {timer >= 60
                        ? "01:" + (Math.floor(timer) - 60 + "0")
                        : "00:" +
                          (timer >= 10
                            ? Math.floor(timer)
                            : "0" + Math.floor(timer))}
                    </span>
                  </>
                ) : (
                  <div
                    className={cn(styles.resend_code)}
                    onClick={sendCodeHandler}
                  >
                    {/*Повторно отправить код {sendCodeCounter} / 5*/}
                    Отправить еще раз
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
