import {
  applicationBasisType,
  createApplicationType,
  updateApplicationType,
  uploadDocumentType,
  verifyApplicationType,
} from "./types";
import { mainAxios } from "../../index";

class ApplicationClass {
  user_id: string | null = null;

  constructor() {}

  getApplications = () => mainAxios.get("/application");

  getApplication = ({ applicationId }: applicationBasisType) =>
      mainAxios.get(`/user/${this.user_id}/application/${applicationId}`);

  getPhoto = ({ applicationId }: applicationBasisType) =>
      mainAxios.get(`/user/${this.user_id}/application/${applicationId}`);

  getInn = ({ applicationId }: applicationBasisType) =>
      mainAxios.get(`/user/${this.user_id}/application/${applicationId}/inn`);

  createApplication = (data: createApplicationType) =>
      mainAxios.post(`/user/${this.user_id}/application`, data);

  //TODO API тут логику доработать потом, как бек будет, преобразование значений сделать или типо того
  updateApplication = ({ applicationId, ...data }: updateApplicationType) =>
      mainAxios.put(`/user/${this.user_id}/application/${applicationId}`, data);

  code = ({ applicationId }: applicationBasisType) =>
      mainAxios.post(
          `/user/${this.user_id}/application/${applicationId}/personal-data-agreement`
      );

  verifyApplication = ({ applicationId, code }: verifyApplicationType) =>
      mainAxios.put(
          `/user/${this.user_id}/application/${applicationId}/personal-data-agreement/${code}`
      );

  //TODO API тут будет другая логика работы с документами скорее всего
  uploadDocument = ({
                      applicationId,
                      document_type,
                      ...file
                    }: uploadDocumentType) =>
      mainAxios.post(
          `/user/${this.user_id}/application/${applicationId}/photo/${document_type}`,
          file
      );
  //  /user/{user_id}/application/{application_id}/enrichment где используется???
}

export const applicationApi = new ApplicationClass();
