import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationType } from "../../../types/Applications/types";
import { StateType } from "../../index";

type initialStateType = {
   currentApplication: ApplicationType | null;
   applications: ApplicationType[] | null;
   saveCurrentApplication: boolean;
   loanId: string | null;
};

const initialState: initialStateType = {
   currentApplication: null,
   applications: null,
   saveCurrentApplication: false,
   loanId: "",
};

export const applicationsReducer = createSlice({
   name: "@applicationReducer",
   initialState,
   reducers: {
      setApplications: (state, { payload }: PayloadAction<any>) => {
         state.applications = payload.applications;
         state.currentApplication = payload.applications[0];
      },
      setSaveCurrentApplication: (
         state,
         { payload }: PayloadAction<boolean>
      ) => {
         state.saveCurrentApplication = payload;
      },
      setLoanId: (state, { payload }: PayloadAction<string>) => {
         state.loanId = payload;
      },
      clear: () => initialState,
   },
});

export const applicationsReducerSelectors = {
   getApplications: (state: StateType) =>
      state.applicationsReducer.applications,
   getCurrentApplication: (state: StateType) =>
      state.applicationsReducer.currentApplication,
   getSaveCurrentApplication: (state: StateType) =>
      state.applicationsReducer.saveCurrentApplication,
   getLoanId: (state: StateType) => state.applicationsReducer.loanId,
};
