import { createAction } from "@reduxjs/toolkit";
import {
   approveApplicationType,
   declineApplicationType,
   getAdminDocumentType,
   getDocumentType,
   updateApplicationType,
} from "../api/underwriter/types";

export const underwriterActions = {
   approveApplication: createAction<approveApplicationType>(
      "underwriter/approveApplication"
   ),
   declineApplication: createAction<declineApplicationType>(
      "underwriter/declineApplication"
   ),
   updateApplication: createAction<updateApplicationType>(
      "underwriter/updateApplication"
   ),
   downloadFile: createAction<getDocumentType>("underwriter/downloadFile"),

   pingApplications: createAction<{
      delay?: number;
   }>("underwriter/pingApplications"),

   getApplications: createAction("/underwriter/getApplication"),
};

export const adminActions = {
   downloadFile: createAction<getAdminDocumentType>("admin/downloadFile"),
};
