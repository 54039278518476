import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/icons";

type propsType = {};
export const Header: FC<propsType> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.creddy_logo}>
        <Icons.logo.logoMain className={styles.logo_svg} />
      </div>
    </div>
  );
};
