import cn from "classnames";
import { inputWrapperPropsType } from "../types";
import { Icons } from "../../../../assets/icons";
import styles from "../index.module.css";

export const InputWrapper = ({
  variantWrapper = "default",
  ...props
}: inputWrapperPropsType) => {
  return (
    <div
      className={cn(props.className, styles.input_wrapper, {
        [styles.wrapper]: variantWrapper === "default",
      })}
    >
      {props.children}
      {props.label && <label className={styles.label}>{props.label}</label>}
      {props.support && (
        <div
          className={cn(styles.support, {
            [styles.support_slim]: variantWrapper !== "default",
          })}
        >
          {props.support}
        </div>
      )}
      {props.readOnly && !props.support && (
        <div className={styles.support}>
          <Icons.ui.Lock />
        </div>
      )}
      {Boolean(props.error) && (
        <div title={props.error} className={styles.error}>
          <span className={styles.exclamation_mark}>!</span>
          {props.error}
        </div>
      )}
    </div>
  );
};
