import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../util/helpers/processStateController";
import { call, delay, put, select } from "redux-saga/effects";
import { API } from "../../api";
import { sagaApiType } from "../util/types";
import {
  applicationsReducer,
  applicationsReducerSelectors,
} from "../../store/reducers/applications";
import { sagaActions } from "../util/sagaActions";
import { authSagaActions } from "common_modules/auth/sagas/actions";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";

export function* pingApplicationsSaga({
  payload,
  type: actionType,
}: PayloadAction<{
  delay?: number;
}>) {
  const process = processStateController(actionType);

  try {
    yield process.start();
    if (payload.delay) {
      yield delay(payload.delay);
    }

    const saveCurrentApplication: boolean = yield select(
      applicationsReducerSelectors.getSaveCurrentApplication
    );

    const applications: sagaApiType = yield call(
      API.underwriter.getApplications
    );

    if (!saveCurrentApplication) {
      yield put(
        applicationsReducer.actions.setApplications({
          applications: applications?.data?.data,
          currentApplication: applications?.data?.data[0],
        })
      );
    } else {
      const currentApplication: boolean = yield select(
        applicationsReducerSelectors.getCurrentApplication
      );

      yield put(
        applicationsReducer.actions.setApplications({
          applications: [currentApplication, ...applications?.data?.data],
          currentApplication: currentApplication,
        })
      );
    }

    yield put(
      sagaActions.underwriter.pingApplications({
        delay: 10000,
      })
    );
  } catch (e: any) {
  } finally {
    if (!payload.delay) {
      const actionType = authSagaActions.autoAuth.type;
      yield put(setProcessLoading(actionType, false));
    }

    // yield process.stop();
  }
}
