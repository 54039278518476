import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { setProcessLoading } from "store/auxiliary/loadingReducer";

export const redirect = (route: string) => {
  return put(push(route));
};

export const processStateController = (actionType: string) => ({
  start: () => put(setProcessLoading(actionType, true)),
  stop: () => put(setProcessLoading(actionType, false)),
});
