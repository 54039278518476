import * as axios from "axios";
import { AxiosResponse } from "axios";
import {
  approveApplicationType,
  declineApplicationType,
  getDocumentType,
  getPhotoType,
  underwriterBasisType,
} from "./types";

export const underwriterAxios = axios.default.create({
  baseURL: process.env.REACT_APP_UNDERWRITER_URL,
  timeout: 30000,
});

class UnderwriterApiClass {
  constructor() {}

  //Получить информацию обо всех заявках андеррайтера
  getApplications = (): Promise<AxiosResponse<any>> =>
     underwriterAxios.get("/application");

  //Получить информацию о заявке
  getApplication = ({ applicationId }: underwriterBasisType) =>
     underwriterAxios.get(`/application/${applicationId}`);

  //Обновить информацию о заявке
  updateApplication = ({
                         applicationId,
                         ...applicationBody
                       }: underwriterBasisType) =>
     underwriterAxios.put(`/application/${applicationId}`, applicationBody);

  //Принять решение об одобрении заявки
  approveApplication = ({ applicationId, ...body }: approveApplicationType) =>
     underwriterAxios.put(`/application/${applicationId}/approve`, body);

  //Принять решение об отказе по заявке
  declineApplication = ({ applicationId, ...body }: declineApplicationType) =>
     underwriterAxios.put(`/application/${applicationId}/decline`, body);

  //Скачать документ
  downloadFile = ({ applicationId, document_type }: getDocumentType) =>
     underwriterAxios.get(
        `/application/${applicationId}/document/${document_type}`
     );

  getPhotoFull = ({ applicationId, photo_type }: getPhotoType) =>
     underwriterAxios.get(`/application/${applicationId}/photo/${photo_type}`);

  getPhotoPreview = ({ applicationId, photo_type }: getPhotoType) =>
     underwriterAxios.get(
        `/application/${applicationId}/photo/${photo_type}/preview`
     );
}

export const underwriterApi = new UnderwriterApiClass();
