import { applicationApi } from "./application";
import { documentsApi } from "./documents";
import { adminApi } from "../admin";


export const mainApi = {
  application: applicationApi,
  documents: documentsApi,
  admin: adminApi,
};
