import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Controller } from "react-hook-form";
import mergeRefs from "react-merge-refs";
import get from "lodash/get";
import cn from "classnames";
import styles from "../index.module.css";
import { TextInputPropsType } from "../types";
import { ConnectedForm } from "../../ConnectedForm";
import { InputWrapper } from "../InputWrapper";

export const InputNumber = (
  props: Omit<TextInputPropsType, "control"> & {
    suffix?: string;
    thousandsGroupStyle?: "thousand";
    maxLength?: number;
    noNumericString?: boolean;
    decimalScale?: number;
  } & NumberFormatProps<any>
) => {
  const {
    inputElementRef,
    variant,
    control,
    support,
    noNumericString,
    ...jsxAttrs
  } = props;

  return (
    <ConnectedForm>
      {({ control, formState: { errors } }) => (
        <InputWrapper
          {...props}
          error={props.error || get(errors as any, `${props.name}.message`)}
        >
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...jsxAttrs}
                className={cn(styles.Input, {
                  [styles.Input_error]: props.error,
                  [styles.Input_large]: variant === "large",
                })}
                getInputRef={mergeRefs([field.ref, props.inputElementRef])}
                {...field}
                thousandsGroupStyle={props.thousandsGroupStyle}
                allowNegative={false}
                placeholder={"0"}
                mask={""}
                suffix={props.suffix}
                format={props.format}
                decimalScale={
                  props.decimalScale == undefined ? 2 : props.decimalScale
                }
                onPaste={props.onPaste}
                thousandSeparator={props.thousandsGroupStyle ? " " : undefined}
                isNumericString={!props.noNumericString}
                maxLength={props.maxLength || 11}
              />
            )}
            defaultValue={props.defaultValue}
            name={props.name}
            control={control}
          />
        </InputWrapper>
      )}
    </ConnectedForm>
  );
};
