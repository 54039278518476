import { ImageFactory } from "../../helpers/imageFactory";

//logo
import Logo from "./logo/Logo.svg";

//main
import Exit from "./main/Exit.svg";
import { ReactComponent as Triangle } from "./main/triangle.svg";
import Pencil from "./main/pencil.svg";
import Exiticon from "./main/exit.svg";
import Active from "./main/activeStatus.svg";
import Inactive from "./main/inActiveStatus.svg";
import Crossfilter from "./main/CrossFilter.svg";
import DocumentUpload from "./main/documentUpload.svg";

//ui
import Lock from "./ui/lock.svg";
import PencilBlack from "./ui/pencilBlack.svg";
import PencilBlackNew from "./ui/PencilNewPhone.svg";
import Rub from "./ui/rub.svg";
import Percent from "./ui/percent.svg";
import { ReactComponent as Loader } from "./ui/loader.svg";
import arrowAngleIcon from "./ui/ArrowAngle.svg";
import Close from "./ui/close.svg";
import CloseWhite from "./ui/closeWhite.svg";
import Date from "./ui/date.svg";
import ArrowIcon from "./ui/Arrow.svg";
import Calendar from "./ui/calendar.svg";
import Document from "./ui/document.svg";
import CheckmarkInCircle from "./ui/checkmarkInCircle.svg";
import File from "./ui/file.svg";
import CheckmarkGreen from "./ui/checkmark_green.svg";
import { ReactComponent as CheckmarkInCircleWhite } from "./ui/checkmarkInCircleWhite.svg";
import { ReactComponent as CheckmarkWithLoaderWhite } from "./ui/checkmarkWithLoaderWhite.svg";
import { ReactComponent as Exclamation } from "./ui/Exclamation.svg";
import { ReactComponent as IconForElpts } from "./ui/IconForElpts.svg";

// Icons from main
import { ReactComponent as Burger } from "./ui/burger.svg";
import { ReactComponent as Camera } from "./ui/camera.svg";
import { ReactComponent as CautionTriangle } from "./ui/CautionTriangle.svg";
import { ReactComponent as Checkbox_checked } from "./ui/checkbox_checked.svg";
import { ReactComponent as Checkbox_unchecked } from "./ui/checkbox_unchecked.svg";
import { ReactComponent as Checkmark } from "./ui/checkmark.svg";
import { ReactComponent as Checkmark_no_padding } from "./ui/checkmark_no_padding.svg";
import { ReactComponent as CheckmarkWithLoader } from "./ui/checkmarkWithLoader.svg";
import { ReactComponent as EyeClosed } from "./ui/close_eye.svg";
import { ReactComponent as Cross } from "./ui/cross.svg";
import { ReactComponent as CrossWarning } from "./ui/cross_warning.svg";
import { ReactComponent as EmptyCircle } from "./ui/emptyCircle.svg";
import { ReactComponent as ErrorExclamation } from "./ui/errorExclamation.svg";
import { ReactComponent as Eye } from "./ui/eye.svg";
import { ReactComponent as Hourglass } from "./ui/hourglass.svg";
import { ReactComponent as MessageBoxLoading } from "./ui/messageBoxLoading.svg";
import { ReactComponent as Pdf } from "./ui/pdf.svg";
import { ReactComponent as Ruble } from "./ui/ruble.svg";
import { ReactComponent as RUSSIA } from "./ui/RUSSIA.svg";
import { ReactComponent as Stacks } from "./ui/stacks.svg";
import { ReactComponent as TransparentRoundCheckmark } from "./ui/TransparentRoundCheckmark.svg";
import { ReactComponent as UndoArrow } from "./ui/undoArrow.svg";
import { ReactComponent as SmallTriangle } from "./ui/SmallTriangle.svg";
import { ReactComponent as Scoring } from "./ui/Scoring.svg";
import { ReactComponent as TriangleSideBar } from "./ui/triangle.svg";
import { ReactComponent as ArrowStroke } from "./ui/ArrowStroke.svg";
import { ReactComponent as ReturnsIcon } from "./ui/ReturnsIcon.svg";

export const Icons = {
   logo: {
      logoMain: ImageFactory(Logo, "Logo"),
   },
   main: {
      Exit: ImageFactory(Exit, "Exit"),
      Triangle: Triangle,
      pencilL: ImageFactory(Pencil, "Pencil"),
      Exiticon: ImageFactory(Exit, "Exiticon"),
      Active: ImageFactory(Active, "Active"),
      Inactive: ImageFactory(Inactive, "Inactive"),
      Crossfilter: ImageFactory(Crossfilter, "Crossfilter"),
      DocumentUpload: ImageFactory(DocumentUpload, "DocumentUpload"),
   },
   ui: {
      IconForElpts,
      Exclamation,
      CheckmarkWithLoaderWhite,
      CheckmarkInCircleWhite,
      Exit,
      ReturnsIcon,
      CheckmarkGreen: ImageFactory(CheckmarkGreen, "CheckmarkGreen"),
      File: ImageFactory(File, "File"),
      Lock: ImageFactory(Lock, "Lock"),
      PencilBlack: ImageFactory(PencilBlack, "PencilBlack"),
      PencilBlackNew: ImageFactory(PencilBlackNew, "PencilBlackNew"),
      Rub: ImageFactory(Rub, "Rub"),
      Percent: ImageFactory(Percent, "Percent"),
      arrowAngleIcon: ImageFactory(arrowAngleIcon, "arrowAngleIcon"),
      Close: ImageFactory(Close, "Close"),
      CloseWhite: ImageFactory(CloseWhite, "CloseWhite"),
      Date: ImageFactory(Date, "Date"),
      arrowIcon: ImageFactory(ArrowIcon, "arrowIcon"),
      Calendar: ImageFactory(Calendar, "Calendar"),
      Document: ImageFactory(Document, "Document"),
      CheckmarkInCircle: ImageFactory(CheckmarkInCircle, "CheckmarkInCircle"),
      ArrowStroke,
      TriangleSideBar,
      Burger,
      Camera,
      CautionTriangle,
      Checkbox_checked,
      Checkbox_unchecked,
      Checkmark,
      Checkmark_no_padding,
      CheckmarkWithLoader,
      EyeClosed,
      Cross,
      CrossWarning,
      EmptyCircle,
      ErrorExclamation,
      Eye,
      Hourglass,
      MessageBoxLoading,
      Pdf,
      Ruble,
      RUSSIA,
      Stacks,
      TransparentRoundCheckmark,
      UndoArrow,
      Loader,
      SmallTriangle,
      Scoring,
   },
};
