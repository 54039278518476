import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../util/helpers/processStateController";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { sagaActions } from "../util/sagaActions";
import { applicationsReducer } from "../../store/reducers/applications";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { documentsReducer } from "../../store/reducers/documents";
import { underwriterActions } from "../actions";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";

export function* getApplicationsSaga({
  payload,
  type: actionType,
}: PayloadAction) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const applications: sagaApiType = yield call(
      API.underwriter.getApplications
    );

    yield put(documentsReducer.actions.clear());

    yield put(
      setProcessLoading(sagaActions.underwriter.declineApplication.type, false)
    );
    yield put(
      setProcessLoading(sagaActions.underwriter.approveApplication.type, false)
    );

    yield put(
      applicationsReducer.actions.setApplications({
        applications: applications?.data?.data,
        currentApplication: applications?.data?.data[0],
      })
    );
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.declineApplication.type,
    });
  } finally {
    yield process.stop();
  }
}
