import React, { HTMLAttributes } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { Loader } from "../Loader";

type propsType = {
   margin?: "small" | "medium";
   form?: string;
   disabled?: boolean;
   size?: "small" | "big" | "regular";
   variant?: "default" | "underline" | "black" | "outline" | "warning";
   loading?: boolean;
   type?: "submit" | "reset" | "button";
} & HTMLAttributes<HTMLButtonElement>;

export const Button = ({
   children,
   margin,
   size = "small",
   variant = "default",
   loading,
   disabled,

   ...jsxAttr
}: propsType) => {
   return (
      <button
         disabled={disabled || loading}
         {...jsxAttr}
         className={cn(styles.container, jsxAttr.className, {
            [styles[`margin--${margin}`]]: margin,
            [styles.regular_container]: size === "regular",
            [styles[`${variant}`]]: variant,
            [`Button--loading`]: loading,
         })}
      >
         {loading ? <Loader /> : children}
      </button>
   );
};
