import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authReducerType } from "./types";
import { lsController } from "store/auxiliary/localStorage";
/*
  Сейчас setTokenToAxios вынесен модуль и туда импортируются все апи классы приложения
 */
import { setTokenToAxios } from "common_modules/auth/api/methods/setTokenToAxios";
// common_modules
import { setUserIdToApiClass } from "common_modules/auth/api/methods/setUserIdToApiClass";
import { clearState } from "store/auxiliary/clearState";
import { authRoutes } from "../authRoutes";
import { redirect } from "../helpers";

/*
  userReducer вынес в модуль ауса
  lsController и setTokenToAxios (взаимодействие с апи слоем) импортируются из основого проекта
*/
const initialState: authReducerType = {
  user_id: null,
  password: null,
  code: null,
  token: null,
  user_role: null,
};

export const userReducer = createSlice({
  name: "@userReducer",
  initialState,
  reducers: {
    setPhone(state, { payload }: PayloadAction<{ phone?: string }>) {
      state.phone = payload.phone;
      lsController.set("phone", payload.phone || null);
    },

    setPassword(
      state,
      { payload }: PayloadAction<{ password: string | null }>
    ) {
      state.password = payload.password;
    },

    setId(state, { payload }: PayloadAction<{ user_id: string | null }>) {
      state.user_id = payload.user_id;
      setUserIdToApiClass(payload.user_id);
    },

    setCode(state, { payload }: PayloadAction<{ code: string | null }>) {
      state.code = payload.code;
    },

    setToken(state, { payload }: PayloadAction<{ token: string | null }>) {
      state.token = payload.token;
      setTokenToAxios(payload.token);
    },

    setUserRole(
      state,
      {
        payload,
      }: PayloadAction<{ user_role: "user" | "underwriter" | "admin" }>
    ) {
      state.user_role = payload.user_role;

      lsController.set("user_role", payload.user_role || null);
    },

    /* clear */
    clear: () => {
      setTokenToAxios(null);
      setUserIdToApiClass(null);
      return initialState;
    },
  },
});

export const logout = {
  saga: function* logoutSaga() {
    // TODO API потом протестить, нормально ли работает?
    console.log("logout saga");
    yield clearState();
    lsController.clearAll();
    yield redirect(authRoutes.phone);
  },
  default: (dispatch: any) => {
    clearState();
    lsController.clearAll();
    // window.location.href = `${process.env.REACT_APP_MAIN_LINK}`;
  },
};
