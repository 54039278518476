import * as axios from "axios";
import {
   createPasswordType,
   createUserType,
   getCodeType,
   getTokenType,
   verifyCodeType,
} from "./types";
import { stringHelpers } from "../../helpers/string";
import { dataToQueryString } from "../methods/dataToQueryString";

/*
   TODO скорее всего этот файл дублирует такой же из common_modules и подлежит удалению.
   Сейчас некогда разбираться
 */
export const authAxios = axios.default.create({
   baseURL: process.env.REACT_APP_AUTH_URL,
   timeout: 30000,
});

class AuthApiClass {
   user_id: string | null = null;

   constructor() {}

   getUsers = (data: any) => authAxios.get(`/user${dataToQueryString(data)}`);

   get = ({ phone }: { phone: string }) =>
      authAxios.get(`/user?phone_number=${stringHelpers.clearPhone(phone)}`);

   create = ({ phone, ...body }: createUserType) =>
      authAxios.post("/user", {
         phone_number: stringHelpers.clearPhone(phone),
         ...body,
      });

   code = (props: getCodeType) =>
      authAxios.post(`/user/${this.user_id}/code`, {
         template: props.smsTemplate,
         target: "phone",
      });

   verifyCode = (props: verifyCodeType) =>
      authAxios.head(`/user/${this.user_id}/code/${props.code}`);

   createPassword = ({ code, ...body }: createPasswordType) =>
      authAxios.put(`/user/${this.user_id}/code/${code}`, body);

   token = (body: getTokenType) =>
      authAxios.post(`/user/${this.user_id}/token`, body);

   getUserTokenized = () => authAxios.get(`/user/${this.user_id}`);

   getPdDocument = ({ id }: { id: string }) => authAxios.get(`/pd/${id}`);
}

export const authApi = new AuthApiClass();
