import { PayloadAction } from "@reduxjs/toolkit";
import { getTokenType } from "../types";
import { processStateController, redirect } from "../../helpers";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { sagaApiType } from "../types";
import { userReducer } from "common_modules/auth/userReducer";
import { errorsReducer } from "store/auxiliary/errorsReducer";
import { authApi } from "../../api";
import { routes } from "routes";
import { sagaActions } from "../../../../sagas/util/sagaActions";

export function* getTokenSaga({
  payload,
  type: actionType,
}: PayloadAction<getTokenType>) {
  const loadingProcess = processStateController(actionType);

  try {
    yield loadingProcess.start();

    const userTokenResponse: sagaApiType = yield call(authApi.token, {
      password: payload.password,
    });

    const { token } = userTokenResponse.data.data;

    yield put(
      userReducer.actions.setToken({
        token,
      })
    );

    const response: sagaApiType = yield call(authApi.getUserTokenized);
    const user = response.data.data;

    // TODO вынести в аргумент
    if (payload?.requiredUserRole) {
      if (user?.user_role !== payload.requiredUserRole) {
        yield put(
          errorsReducer.actions.setFieldsErrors({
            password: "Неверная роль пользователя",
          })
        );
        return;
      }
    }

    yield put(
      userReducer.actions.setUserRole({
        user_role: user.user_role,
      })
    );

    if (process.env.REACT_APP_USER_ROLE === "underwriter") {

    yield put(
      sagaActions.underwriter.pingApplications({
      }))
    }

    yield redirect(routes.main.applications);
  } catch (e: any) {
    if (
      e?.response?.status &&
      (e.response?.status == 401 || e.response?.status == 400)
    ) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          password: "Неверный пароль",
        })
      );
      yield loadingProcess.stop();
    } else {
      yield call(errorHandlerSaga, {
        response: e,
      });
    }
  } finally {
    yield loadingProcess.stop();
  }
}
