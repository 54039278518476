import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, delay } from "redux-saga/effects";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { declineApplicationType } from "../../api/underwriter/types";
import { applicationsReducer } from "../../store/reducers/applications";
import { documentsReducer } from "../../store/reducers/documents";
import { processStateController } from "../util/helpers/processStateController";

export function* declineApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<declineApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.underwriter.declineApplication,
      payload
    );

    yield put(applicationsReducer.actions.setSaveCurrentApplication(true));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.declineApplication.type,
    });
  } finally {
    // yield process.stop();
    // yield put(
    //   setProcessLoading(sagaActions.underwriter.declineApplication.type, false)
    // );
  }
}
