import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { setProcessLoading } from "../../store/auxiliary/loadingReducer";
import { sagaActions } from "../util/sagaActions";
import { sagaApiType } from "../util/types";
import { API } from "../../api";
import { errorHandlerSaga } from "../util/helpers/errorHandlerSaga";
import { approveApplicationType } from "../../api/underwriter/types";
import { processStateController } from "../util/helpers/processStateController";
import { applicationsReducer } from "../../store/reducers/applications";

export function* approveApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<approveApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const response: sagaApiType = yield call(
      API.underwriter.approveApplication,
      payload
    );

    yield put(applicationsReducer.actions.setSaveCurrentApplication(true));
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: sagaActions.underwriter.approveApplication.type,
    });
  } finally {
  }
}
