import { PayloadAction } from "@reduxjs/toolkit";
import { verifyCodeType } from "../types";
import { processStateController } from "../../helpers";
import { sagaApiType } from "../types";
import { call, put } from "redux-saga/effects";
import { userReducer } from "common_modules/auth/userReducer";
import { redirect } from "../../helpers";
import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { errorsReducer } from "store/auxiliary/errorsReducer";
import { authApi } from "../../api";
import { authRoutes } from "../../authRoutes";

export function* verifyCodeSaga({
  payload,
  type: actionType,
}: PayloadAction<verifyCodeType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();
    const response: sagaApiType = yield call(authApi.verifyCode, payload);

    yield put(
      userReducer.actions.setCode({
        code: payload.code,
      })
    );

    yield redirect(authRoutes.restore_password);
  } catch (e: any) {
    if (e?.response?.status == 404 || e?.response?.status == 400) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          code: "Неверный код!",
        })
      );
    } else {
      yield call(errorHandlerSaga, {
        response: e,
      });
    }
  } finally {
    yield process.stop();
  }
}
