import { createAction } from "@reduxjs/toolkit";
import { errorHandlerSagaType } from "./helpers/errorHandlerSaga";
import { adminActions, underwriterActions } from "../actions";
import { authSagaActions } from "common_modules/auth/sagas/actions";

export const sagaActions = {
   Auth: authSagaActions,
   admin: adminActions,
   // auth: authSagaActions,
   // application: applicationSagaActions,
   // factoring: applicationFactoringSagaActions,
   // user: userSagaActions,
   // creditLine: creditLineSagaActions,
   underwriter: underwriterActions,
   error: createAction<errorHandlerSagaType>("error"),
};
