import { spawn } from "redux-saga/effects";
import { clearErrorsSaga } from "./util/helpers/clearErrorsSaga";
import { errorWatcherSaga } from "./util/helpers/errorHandlerSaga";
import { autoAuthSaga } from "common_modules/auth/sagas/autoAuthSaga";
import { authWatcher } from "common_modules/auth/sagas";
import { allAuthSaga } from "common_modules/auth";
import { underwriterSaga } from "./underwriter";
import { adminSaga } from "./admin";

export function* rootSaga() {
   //init sagas
   yield spawn(autoAuthSaga);
   yield spawn(allAuthSaga);
   // main sagas
   yield spawn(authWatcher);
   yield spawn(underwriterSaga);
   yield spawn(adminSaga);

   //Errors sagas
   yield spawn(clearErrorsSaga);
   yield spawn(errorWatcherSaga);
}
