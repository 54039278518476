import { errorHandlerSaga } from "sagas/util/helpers/errorHandlerSaga";
import { call, put } from "redux-saga/effects";
import { setProcessLoading } from "store/auxiliary/loadingReducer";
import { authSagaActions } from "../actions";
import { lsController } from "store/localStorage";
import { clearState } from "store/auxiliary/clearState";
import { authRoutes } from "../../authRoutes";
import { redirect } from "../../helpers";

export function* LogoutSaga() {
  const actionType = authSagaActions.logoutSaga.type;
  yield put(setProcessLoading(actionType, true));
  try {
    yield clearState();
    lsController.clearAll();
    yield redirect(authRoutes.phone);
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
    });
  } finally {
    yield put(setProcessLoading(actionType, false));
  }
}
