export const stringHelpers = {
   clearPhone: (val: string) => {
      if (!val) return "";
      return val.replace(/\D+/g, "");
   },

   clearString: (val: string) => {
      if (!val) return "";
      return val.replace(/\D+/g, "");
   },

   transformMoneyValue: (val: string | number | undefined, div = ",") => {
      if (!val) return "";
      let newString = String(val);
      const indexOfDots = newString.indexOf(".");
      let tail = "";
      if (indexOfDots != -1) {
         tail = div + newString.substring(indexOfDots + 1);
         newString = newString.substring(0, indexOfDots);
      }

      let temp: Array<string> = [];
      let array = Array.from(newString);
      let iterator = 0;

      for (let i = array.length - 1; i >= 0; i--) {
         if (iterator % 3 == 0) {
            temp.push(" ");
         }
         temp.push(array[i]);
         iterator++;
      }

      return temp.reverse().join("").trim() + tail;
   },

   phoneNormalize: (phone: string | null) => {
      if (typeof phone === "string") {
         if (phone.includes("+")) return phone;
         return `+${phone?.slice(0, 1)} ${phone?.slice(1, 4)} ${phone?.slice(
            4,
            7
         )} ${phone?.slice(7, 9)} ${phone?.slice(9)}`;
      } else {
         return "-";
      }
   },

   divisionCodeNormalize: (code: string | null) => {
      if (typeof code === "string") {
         if (code.includes("-") && code.length === 6) return code;
         return `${code?.slice(0, 3)}-${code?.slice(3, 6)}`;
      } else {
         return "-";
      }
   },

   regNumber: (regNumber: string | null) => {
      if (typeof regNumber === "string") {
         return `${regNumber?.slice(0, 1)} ${regNumber?.slice(
            1,
            4
         )} ${regNumber?.slice(4, 6)} ${regNumber?.slice(6)}`;
      } else {
         return "-";
      }
   },
};

export const declinationFromTheNumber = (
   number: number,
   one: string,
   two: string,
   five: string
) => {
   let n = Math.abs(number);
   n %= 100;
   if (n >= 5 && n <= 20) {
      return five;
   }
   n %= 10;
   if (n === 1) {
      return one;
   }
   if (n >= 2 && n <= 4) {
      return two;
   }
   return five;
};

export const onPastePhoneNumberHandler = (e: any) => {
   const value: string = e.clipboardData.getData("text/plain");

   if (value) {
      const symbolsToDelete = [" "];
      const formattedValue = Array.from(value)
         .filter(
            (char) =>
               !symbolsToDelete.includes(char) && !Number.isNaN(Number(char))
         )
         .join("");
      if (formattedValue.length == 11) {
         e.target.value = formattedValue.substring(1);
      }
   }
};

export const firstLetterUpperCase = (string: string) => {
   return string.charAt(0).toUpperCase() + string.slice(1);
};
