export const routes = {
   root: "/",
   sandbox: "/sandbox",
   // Оставил тут аус роуты чисто чтобы они были в девтулзе данька
   auth: {
      root: "/user",
      phone: "/user/phone",
      code: "/user/code",
      password: "/user/password",
      restore_password: "/user/restore_password",
   },

   main: {
      root: "/main",
      applications: "/main/applications",
      application: "/main/applications/:applicationId",
      loans: "/main/loans",
      loan: "/main/loans/:loanId",
      clients: "/main/clients",
      client: "/main/clients/:clientId",
      operations: "/main/operations",
      operationsPayments: "/main/operationsPayments",
      dashboard: "/main/dashboard",
      reports: "/main/reports",
      payment: "/main/payment",
   },
};
